<template>
  <Fieldset legend="แบบฟอร์มบันทึกข้อมูลพื้นที่">
    <div class="p-d-flex p-jc-between">
      <div>
        <Button
          label="ย้อนกลับ"
          icon="pi pi-arrow-left"
          class="p-button-sm p-button-warning"
          @click="() => $router.back(-1)"
        />
      </div>
    </div>

    <form @submit.prevent="saveValidatedForm" id="validity">
      <div class="p-fluid p-grid">
        <div class="p-field p-col-12 p-md-4">
          <label for="wight">รหัสพื้นที่</label>
          <InputText
            id="areaCode"
            type="text"
            v-model="forms.areaCode"
            placeholder="รหัสพื้นที่"
            :class="[validates['areaCode']]"
            :required="true"
          />
          <InlineMessage v-if="validates['areaCode']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="wight">ชื่อพื้นที่</label>
          <InputText
            id="areaName"
            type="text"
            v-model="forms.areaName"
            placeholder="ชื่อพื้นที่"
            :class="[validates['areaName']]"
            :required="true"
          />
          <InlineMessage v-if="validates['areaName']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="wight">ชื่อผู้ติดต่อ</label>
          <InputText
            id="contactPerson"
            type="text"
            v-model="forms.contactPerson"
            placeholder="ชื่อผู้ติดต่อ"
            :class="[validates['contactPerson']]"
            :required="true"
          />
          <InlineMessage v-if="validates['contactPerson']"
            >ห้ามว่าง</InlineMessage
          >
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="wight">หมายเลขโทรศัพท์มือถือ</label>
          <InputMask
            id="mobile"
            type="text"
            v-model="forms.mobile"
            placeholder="หมายเลขโทรศัพท์มือถือ"
            :class="[validates['mobile']]"
            mask="9999999999"
            :required="true"
          />
          <InlineMessage v-if="validates['mobile']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="wight">หมายเลขโทรศัพท์บ้าน/ที่ทำงาน</label>
          <InputMask
            id="tel"
            type="text"
            v-model="forms.tel"
            placeholder="หมายเลขโทรศัพท์บ้าน/ที่ทำงาน"
            mask="999999999"
          />
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="wight">อีเมล</label>
          <InputText
            id="email"
            type="email"
            v-model="forms.email"
            placeholder="อีเมล"
            :class="[validates['email']]"
            :required="true"
          />
          <InlineMessage v-if="validates['email']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="wight">ที่อยู่</label>
          <Textarea
            id="address"
            v-model="forms.address"
            placeholder="ที่อยู่"
            :class="[validates['address']]"
            :required="true"
          />
          <InlineMessage v-if="validates['address']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="wight">จังหวัด</label>
          <Dropdown
            v-model="forms.provinceId"
            :options="this.$store.state.address.provinces"
            optionLabel="nameTh"
            optionValue="id"
            filterMatchMode="contains"
            :filter="true"
            placeholder="จังหวัด"
            :class="[validates['province']]"
            @change="selectedProvince($event)"
            :required="true"
          />
          <InlineMessage v-if="validates['provinceId']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="wight">อำเภอ</label>
          <Dropdown
            v-model="forms.amphureId"
            :options="this.$store.state.address.amphures"
            optionLabel="nameTh"
            optionValue="id"
            filterMatchMode="contains"
            :filter="true"
            placeholder="อำเภอ"
            :class="[validates['amphure']]"
            @change="selectedAmphure($event)"
            :required="true"
          />
          <InlineMessage v-if="validates['amphureId']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="wight">ตำบล</label>
          <Dropdown
            v-model="forms.districtId"
            :options="this.$store.state.address.districts"
            optionLabel="nameTh"
            optionValue="id"
            dataKey="zipCode"
            filterMatchMode="contains"
            :filter="true"
            placeholder="ตำบล"
            :class="[validates['district']]"
            @change="selectedDistrict($event)"
            :required="true"
          />
          <InlineMessage v-if="validates['districtId']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="wight">รหัสไปรษณีย์</label>
          <InputMask
            id="zipcode"
            type="text"
            v-model="forms.zipcode"
            placeholder="รหัสไปรษณีย์"
            :class="[validates['zipcode']]"
            mask="99999"
            :required="true"
          />
          <InlineMessage v-if="validates['zipcode']">ห้ามว่าง</InlineMessage>
        </div>
      </div>

      <div class="p-d-flex p-jc-center">
        <Button
          label="บันทึกข้อมูลเข้าระบบ"
          type="submit"
          icon="pi pi-save"
          class="p-button-success"
        />
        <Button
          label="ไม่ทำรายการ"
          type="button"
          icon="pi pi-arrow-left"
          class="p-button-link"
          @click="() => $router.back(-1)"
        />
      </div>
    </form>
  </Fieldset>
</template>
<script>
import * as moment from 'moment'
import { initCustomValidity } from '@/HTMLElement'
export default {
  layout: 'Private',
  data() {
    return {
      dataSet: { elderly: null },
      educations: [1, 2, 3, 4, 5, 6, 7],
      forms: {},
      filteredProvinces: null,
      filteredAmphures: null,
      filteredDistricts: null
    }
  },
  computed: {
    validates() {
      const fields = Object.keys(this.forms).reduce((fields, value) => {
        const _value = this.forms[[value]]
        //console.log("_value ::==", _value);
        return {
          ...fields,
          [value]: _value != null && _value != '' ? '' : 'p-invalid'
        }
      }, {})
      // console.log("fields ::==", fields);
      return fields
    }
  },
  async mounted() {
    await this.fetchData()
    await this.$store.dispatch('address/fetchProvincesOrderNameTh')
    //await this.$store.dispatch("address/fetchAmphures");
    //await this.$store.dispatch("address/fetchDistricts");
    initCustomValidity('validity')
  },
  methods: {
    async fetchData() {
      const { id } = this.$route.params
      if (id) {
        const { status, message, data } = await this.$store.dispatch(
          'area/fetchAreaById',
          id
        )
        console.log(`data :: ${JSON.stringify(data)}`)
        const { district, amphure, province, zipCode } = data
        if (province) {
          await this.$store.dispatch(
            'address/fetchAmphuresByProvinceIdOrderNameTh',
            {
              provinceId: province?.id
            }
          )
        }
        if (amphure) {
          await this.$store.dispatch(
            'address/fetchDistrictsByAmphureIdOrderNameTh',
            {
              amphureId: amphure?.id
            }
          )
        }
        this.forms = {
          ...data,
          provinceId: province?.id,
          amphureId: amphure?.id,
          districtId: district?.id
        }
      } else {
        this.forms = {
          codeId: null,
          areaCode: null,
          areaName: null,
          contactPerson: null,
          mobile: null,
          tel: null,
          email: null,
          address: null,
          zipcode: null,
          createDtm: null,
          createBy: null,
          district: { id: null },
          amphure: { id: null },
          province: { id: null },
          districtId: null,
          amphureId: null,
          provinceId: null
        }
      }
    },
    async saveValidatedForm() {
      const { codeId: userId, userLevel: level } = this.$store.getters[
        'oauth/session'
      ]
      const { districtId, amphureId, provinceId, zipCode } = this.forms
      const payload = {
        ...this.forms,
        userStatus: 'active',
        createDtm: moment().format('YYYY-MM-DD'),
        district: {
          id: districtId
        },
        amphure: {
          id: amphureId
        },
        province: {
          id: provinceId
        },
        createBy: userId
      }
      //console.log("payload ::==" + JSON.stringify(payload));
      if (districtId == null || amphureId == null || provinceId == null) {
        this.$toast.add({
          severity: 'error',
          summary: 'สถานะการทำรายการ',
          detail:
            'กรุณาเลือก จังหวัด อำเภอ ตำบล และรหัสไปรษณีย์ ก่อนการกดบันทึก',
          life: 3000
        })
      } else {
        //confirm before  save
        this.$confirm.require({
          ...this.$primevue.config.locale,
          reject: async () => {
            const {
              status,
              code,
              invalidCode,
              data
            } = await this.$store.dispatch('area/saveArea', payload)
            console.log('status ::==', status)
            console.log('code ::==', code)
            console.log('data ::==', data)
            if (200 == code) {
              this.$router.back(-1)
            } else {
              let message = 'ระบบไม่สามารถทำรายการได้ ติดต่อเจ้าหน้าที่'
              if ('101' == invalidCode) {
                message = 'รหัสพื้นที่ซ้ำ กรุณาตรวจสอบเลขรหัสพื้นที่อีกครั้ง'
              }
              this.$toast.add({
                severity: 'error',
                summary: 'สถานะการทำรายการ',
                detail: message,
                life: 3000
              })
            }
          }
        })
      }
    },
    // Province Filter
    async selectedProvince(event) {
      console.log('event ::==', event)
      const { originalEvent, value: id } = event
      //  const { id, nameTh } = value;
      await this.$store.dispatch(
        'address/fetchAmphuresByProvinceIdOrderNameTh',
        {
          provinceId: id
        }
      )
    },

    // Amphur Filter
    async selectedAmphure(event) {
      console.log('event ::==', event)
      const { originalEvent, value: id } = event
      console.log('id ::==' + JSON.stringify(id))
      //const { id, nameTh, districts } = value;
      //this.$store.commit("address/setDistricts", districts);
      await this.$store.dispatch(
        'address/fetchDistrictsByAmphureIdOrderNameTh',
        {
          amphureId: id
        }
      )
    },

    // Dis Filter
    selectedDistrict(event) {
      console.log('event ::==', event)
      const { originalEvent, value } = event
      console.log('value ::==' + JSON.stringify(value))
      const { id, nameTh, zipCode } = this.$store.state.address.districts.find(
        ({ id, zipCode }) => id == value
      )
      this.forms.zipcode = zipCode
    }
  }
}
</script>
