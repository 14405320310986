
export const initCustomValidity = (formId) => {
    if (!formId) {
        throw Error('กรุณาส่งชื่อไอดีฟอร์มให้ initCustomValidity("ส่งชื่อไอดีฟอร์ม") ด้วยนะจ๊ะ')
    }
    //validity
    const validities = document.querySelectorAll('[required]')
    //console.log('validities ::==', validities)
    document.getElementById(formId).addEventListener('click', () => {
        validities.forEach((_field) => {
            //console.log('_field ::==', _field)
            const placeholder = _field.getAttribute('placeholder')
            const type = _field.getAttribute('type')
            const min = _field.getAttribute('max')
            const max = _field.getAttribute('max')
            const should = _field.getAttribute('should')
            const lenMin = min?.length
            const lenMax = max?.length
            //console.log('placeholder :==', placeholder)
            //console.log('type :==', type)
            if (placeholder) {
                //console.log('_field.validity ::==', _field.validity)
                const { valueMissing, typeMismatch, rangeOverflow, rangeUnderflow } = _field.validity

                if (valueMissing) {
                    _field?.setCustomValidity(`กรุณากรอกข้อมูล  ${placeholder}`)
                } else if (rangeOverflow || rangeUnderflow) {
                    let message = ` `;
                    if (rangeOverflow) {
                        message = `ค่าต้องน้อยกว่าหรือเท่ากับ ${max}`
                    } else if (rangeUnderflow) {
                        message = `ค่าต้องมากกว่าหรือเท่ากับ ${max}`
                    }
                    if (should) {
                        message = `${message} คะแนนไม่ควรมากกว่า ${should} คะแนน`
                    }
                    _field?.setCustomValidity(message)
                } else {
                    if (typeMismatch) {
                        _field?.setCustomValidity(`กรุณากรอกข้อมูล  ${placeholder} ตามรูปแบบที่ต้องการ`)
                    } else {
                        _field?.setCustomValidity('')
                    }
                }
            }

        })
    })
}

export default {

}